import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'

const LogoHeader = ({ classes, logo }) => (
  <div className={classes.LogoHeader}>
    <div className={classes.container}>
      <img className={classes.logo} src={logo} />
    </div>
  </div>
)

export default injectSheet(style)(LogoHeader)
