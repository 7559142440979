import { SPACING } from 'helpers/globals'

export default {
  Content: {
    marginLeft: SPACING.base,
    marginTop: '-2rem',
    marginBottom: SPACING.base
  },
  container: {
    padding: `${SPACING.large}, ${SPACING.base}, ${SPACING.base}`,
    background: 'white'
  }
}
