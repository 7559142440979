import { COLORS, SPACING } from 'helpers/globals'
import { styleFromSm, styleFromMd, styleFromLg } from 'helpers/screens'

export default {
  container: {
    display: 'block',
    ...styleFromMd({
      height: 500,
      display: 'flex',
      flexWrap: 'nowrap'
    }),
    position: 'relative',
    overflow: 'hidden'
  },
  exampleGradient: {
    background: `linear-gradient(${COLORS.white}, ${COLORS.lightestGrey})`,
    transform: 'skew(-15deg)',
    width: '80%',
    zIndex: 1,
    margin: '0 -15%',
    ...styleFromMd({
      margin: '0 -10%'
    })
  },
  imageContainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: COLORS.red,
    backgroundBlendMode: 'multiply',
    opacity: '.9',
    height: 400,
    width: '100%',
    ...styleFromMd({
      height: '100%',
      width: '60%'
    })
  },
  exampleContent: props => ({
    [props.order > 2 ? 'left' : 'right']: 0
  }),
  exampleContentSizing: {
    display: 'block',
    padding: SPACING.base,
    ...styleFromSm({
      padding: SPACING.large
    }),
    ...styleFromMd({
      position: 'absolute',
      zIndex: 2,
      height: '100%',
      display: 'flex',
      width: '50%',
      padding: SPACING.xl
    }),
    ...styleFromLg({
      width: '60%',
      padding: SPACING.xxxl
    })
  }
}
