import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'

const ConceptExample = ({ image, order, children, classes }) => (
  <div className={classes.container}>
    <div
      className={`order-${order} ${classes.imageContainer}`}
      style={{ backgroundImage: `url(${image})` }}
    />
    <div className={`order-2 ${classes.exampleGradient}`} />
    <div
      className={`justify-content-center align-items-center ${classes.exampleContent} ${classes.exampleContentSizing}`}
    >
      {children}
    </div>
  </div>
)

export default injectSheet(style)(ConceptExample)
