import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import LogoHeader from './LogoHeader'
import Content from './Content'
import githubActions from 'assets/images/github-actions.png'
import commandLine from 'assets/images/command-line-white.png'
import kubernetes from 'assets/images/kubernetes.svg'

const Collapsed = ({ classes }) => (
  <div className={classes.collapsedContainer}>
    <LogoHeader logo={githubActions} />
    <Content id='improvedAutomation'>
      <div className={`col-11 ${classes.innerContent}`}>
        <h3>CI/CD Automation</h3>
        <div>
          Improve pipeline performance, reduce spending on cloud computing and
          eliminate nondeterministic outcomes. Let us put hours back in
          your day by building automation that gets out of the way.
        </div>
        <div className='container'>
          <ul className={classes.serviceList}>
            <li className={classes.bold}>Pipeline optimization</li>
            <li className={classes.bold}>CI Parallelization</li>
            <li className={classes.bold}>GitHub Actions migration</li>
            <li className={classes.bold}>Improve reliability</li>
            <li className={classes.bold}>Self-hosted cloud runners</li>
            <li className={classes.bold}>CD Infrastructure / GitOps</li>
          </ul>
        </div>
      </div>
    </Content>
    <LogoHeader logo={kubernetes} />
    <Content id='kubernetes'>
      <div className={classes.innerContent}>
        <h3>Kubernetes Deployment</h3>
        <div>
          Scale your workloads seamlessly using industry best practices.
          We'll equip your team to achieve success with Kubernetes in production.
        </div>
        <div className='container'>
          <ul className={classes.serviceList}>
            <li className={classes.bold}>AWS / GCP / Azure / DO</li>
            <li className={classes.bold}>Infrastructure as Code</li>
            <li className={classes.bold}>Intelligent Autoscaling</li>
            <li className={classes.bold}>Improved Reliability</li>
            <li className={classes.bold}>Observability / Logging</li>
            <li className={classes.bold}>Canary Deployment</li>
          </ul>
        </div>
      </div>
    </Content>
    <LogoHeader logo={commandLine} />
    <Content id='developerTooling'>
      <div className={classes.innerContent}>
        <h3>Developer Tooling</h3>
        <div>
          Onboard team members faster and increase productivity by removing
          obstacles developers face every day. We'll work with your team to
          understand where the challenges are and solve them one by one.
        </div>
        <div className='container'>
          <ul className={classes.serviceList}>
            <li className={classes.bold}>Reproducible Environments</li>
            <li className={classes.bold}>VSCode Remote Containers</li>
            <li className={classes.bold}>Bespoke Developer CLIs</li>
            <li className={classes.bold}>GitHub Codespaces</li>
            <li className={classes.bold}>Local Kubernetes development</li>
            <li className={classes.bold}>Development on Apple M1</li>
          </ul>
        </div>
      </div>
    </Content>
  </div>
)

export default injectSheet(style)(Collapsed)
