import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import softwareLogo from 'assets/images/softwareDevelopment.svg'
import Expanded from './Expanded'
import PageNav from 'components/PageNav'
import Collapsed from './Collapsed'
import PageLink from 'components/PageLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLORS } from 'helpers/globals'
import { Helmet } from 'react-helmet'

const ArrowDownText = ({ text }) => (
  <React.Fragment>
    {text} <FontAwesomeIcon icon='arrow-down' color={COLORS.red} />
  </React.Fragment>
)

const Services = ({ classes }) => (
  <div className={classes.container}>
    <Helmet meta={[{ property: 'og:title', content: 'Services' }]} />
    <PageNav title='JUMP TO:'>
      <PageLink
        href='#platformOperations'
        Text={_ => <ArrowDownText text='PLATFORM / OPERATIONS' />}
      />
      <PageLink
        href='#softwareDevelopment'
        Text={_ => <ArrowDownText text='SOFTWARE DEVELOPMENT' />}
      />
      <PageLink
        href='#processTraining'
        Text={_ => <ArrowDownText text='PROCESS & TRAINING' />}
      />
    </PageNav>
    <div className={`container ${classes.leadingText}`}>
      <div id='platformOperations' className={classes.sectionLink} />
      <img src={softwareLogo} className={classes.centeredLogo} />
      <div className={`${classes.centered} ${classes.contentHeader}`}>
        <h3>Platform / Operations</h3>
        <div className='row'>
          <div className='offset-md-2 col-md-8'>
            Give your team the space they need to do deep work and ship confidently.
            We can help your software team reach their full potential by automating
            repeatable tasks, standardizing tools, and building infrastructure as code.
          </div>
        </div>
      </div>
      <Expanded />
      <Collapsed />
    </div>
  </div>
)

export default injectSheet(style)(Services)
