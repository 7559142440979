import { COLORS, SPACING } from 'helpers/globals'
import { styleUpToSm, styleFromMd } from 'helpers/screens'

export default {
  container: {
    background: COLORS.lightestGrey
  },
  contentHeader: {
    ...styleUpToSm({
      lineHeight: SPACING.large,
      padding: SPACING.large
    })
  },
  centeredLogo: {
    display: 'block',
    margin: 'auto',
    width: 100
  },
  centered: {
    textAlign: 'center'
  },
  leadingText: {
    padding: `${SPACING.xl} 0 6rem 0`,
    position: 'relative'
  },
  sectionLink: {
    position: 'absolute',
    top: `-${SPACING.xxxl}`,
    ...styleFromMd({ top: 0 })
  }
}
