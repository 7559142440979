import { COLORS, SPACING } from 'helpers/globals'
import { hideAtSm } from 'helpers/screens'

export default {
  softwareContainer: {
    paddingTop: SPACING.xl,
    position: 'relative',
    ...hideAtSm
  },
  logoContainer: {
    paddingRight: '0 !important'
  },
  centeredLogoContainer: {
    paddingRight: '0 !important'
  },
  centeredLogo: {
    display: 'block',
    margin: 'auto',
    width: 100
  },
  innerContainer: {
    paddingTop: SPACING.xxl,
    '& a': {
      color: COLORS.red
    }
  },
  content: {
    zIndex: 2
  },
  innerContent: {
    padding: `0 ${SPACING.xxl} ${SPACING.xl} ${SPACING.xxl} !important`
  },
  rightBackgroundContainer: {
    position: 'absolute',
    right: 0,
    zIndex: 0,
    width: '75%',
    height: '100%',
    paddingTop: SPACING.large
  },
  leftBackgroundContainer: {
    position: 'absolute',
    zIndex: 0,
    paddingBottom: SPACING.large,
    width: '75%',
    height: '100%'
  },
  rightBackground: {
    background: COLORS.white,
    width: '100%',
    height: '100%'
  },
  leftBackground: {
    background: COLORS.red,
    width: '100%',
    height: '100%'
  },
  serviceList: {
    margin: 0,
    paddingLeft: SPACING.small,
    '& li': {
      paddingLeft: SPACING.tiny
    }
  },
  angle: {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    right: -4,
    width: 40,
    height: 40,
    transform: 'skew(-15deg)',
    background: COLORS.red
  },
  textLinkContainer: {
    margin: `${SPACING.base} 0`
  },
  bold: {
    fontWeight: 'bold'
  }
}
