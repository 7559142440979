import { COLORS, SPACING } from 'helpers/globals'
import { styleFromMd } from 'helpers/screens'

export default {
  container: {
    background: COLORS.lightestGrey,
    position: 'relative'
  },
  sectionLink: {
    position: 'absolute',
    top: `-${SPACING.xxxl}`,
    ...styleFromMd({ top: 0 })
  },
  headerInner: {
    background: COLORS.white
  },
  headerContainer: {
    padding: `${SPACING.xl} 0`,
    textAlign: 'center'
  },
  centeredLogo: {
    display: 'block',
    margin: 'auto',
    width: 100
  },
  contentContainer: {
    ...styleFromMd({
      maxWidth: 700
    }),
    '& li': {
      marginBottom: SPACING.tiny
    }
  }
}
