import { COLORS, SPACING } from 'helpers/globals'
// import { styleUpToSm, hideFromMd } from 'helpers/screens'

export default {
  LogoHeader: {
    marginRight: SPACING.base,
    height: 155
  },
  container: {
    background: COLORS.red,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: 40,
    alignSelf: 'center',
    margin: 'auto'
  }
}
