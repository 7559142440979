import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import railsServicesLogo from 'assets/images/railsServices.svg'
import ServiceSummary from './ServiceSummary'

const RailsServices = ({ classes }) => (
  <div className={classes.container}>
    <div id='processTraining' className={classes.sectionLink} />
    <div className={classes.titleText}>
      <img src={railsServicesLogo} className={classes.centeredLogo} />
      <h2>Process & Training</h2>
    </div>
    <div className='row no-gutters'>
      <div className='col-lg-6'>
        <ServiceSummary floatRight>
          <h3 className={classes.subtitle}>Practices & Methodology</h3>
          <p>
            Does your company have a documented software development lifecycle
            (SDLC)? The SDLC serves as a playbook for your teams to center on,
            enabling developers to efficiently ship & maintain software that
            fulfills your business goals.
          </p>
          <p>
            Partner with us to build an SDLC unique to your team and backed by industry
            best practices. In our mission to help software teams reach their full
            potential, the SDLC is at the center of everything we do.
          </p>
        </ServiceSummary>
      </div>
      <div className='col-lg-6'>
        <ServiceSummary>
          <h3>Mentoring & Pair Programming</h3>
          <p>
            Help your developers level up faster and enjoy the benefits of increased
            productivity and code quality without pushing your team to burn out. In
            today's developer market, offering mentorship and continuing education
            is more important than ever.
          </p>

          <p>
            Mentoring and pair programming with our clients is among our most fulfilling
            work. We love people and are passionate about sharing the knowledge and wisdom
            we've gained.
          </p>
        </ServiceSummary>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(RailsServices)
