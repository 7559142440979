import { SPACING } from 'helpers/globals'
import { styleFromMd, styleFromXl } from 'helpers/screens'

export default {
  container: {
    margin: SPACING.base,
    ...styleFromMd({
      margin: SPACING.xxl
    }),
    ...styleFromXl({
      maxWidth: 596,
      float: props => (props.floatRight ? 'right' : '')
    })
  }
}
