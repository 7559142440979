import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'

const Content = ({ classes, children }) => (
  <div className={classes.Content}>
    <div className={classes.container}>{children}</div>
  </div>
)

export default injectSheet(style)(Content)
