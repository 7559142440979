import { COLORS, SPACING, FONT_SIZES } from 'helpers/globals'
import { styleFromMd } from 'helpers/screens'

export default {
  container: {
    backgroundColor: COLORS.darkGrey,
    color: COLORS.white,
    position: 'relative',
    '& .col-lg-6': {
      background: `linear-gradient(to right, #383536, ${COLORS.darkGrey})`
    }
  },
  sectionLink: {
    position: 'absolute',
    top: `-${SPACING.xxxl}`,
    ...styleFromMd({ top: 0 })
  },
  centeredLogo: {
    display: 'block',
    margin: 'auto',
    width: 100
  },
  titleText: {
    textAlign: 'center',
    padding: `${SPACING.xl} 0`
  },
  linkContainer: {
    fontSize: FONT_SIZES.small,
    '& a': { color: COLORS.red }
  },
  subtitle: {
    marginTop: 0
  }
}
