import { COLORS, SPACING } from 'helpers/globals'
import { hideFromSm } from 'helpers/screens'

export default {
  collapsedContainer: {
    position: 'relative',
    ...hideFromSm
  },
  innerContent: {
    lineHeight: SPACING.large,
    padding: `${SPACING.large} ${SPACING.xl} ${SPACING.xl} ${SPACING.xl} !important`
  },
  bold: {
    fontWeight: 700
  },
  serviceList: {
    margin: 0,
    paddingLeft: SPACING.small,
    '& li': {
      paddingLeft: SPACING.tiny
    },
    marginTop: SPACING.large
  },
  textLinkContainer: {
    margin: `${SPACING.base} 0`,
    '& a': {
      color: COLORS.red
    }
  }
}
