import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import githubActions from 'assets/images/github-actions.png'
import commandLine from 'assets/images/command-line-white.png'
import kubernetes from 'assets/images/kubernetes.svg'

const Services = ({ classes }) => (
  <div className={`container ${classes.softwareContainer}`}>
    <div className={classes.leftBackgroundContainer}>
      <div className={classes.leftBackground} />
      <div className={classes.angle} />
    </div>
    <div className={classes.rightBackgroundContainer}>
      <div className={classes.rightBackground} />
    </div>
    <div className={classes.innerContainer}>
      <div id='improvedAutomation' className={`row ${classes.content}`}>
        <div className={`col-3 ${classes.logoContainer}`}>
          <img className={classes.centeredLogo} src={githubActions} />
        </div>
        <div className={`col-9 ${classes.innerContent}`}>
          <h3>CI/CD Automation</h3>
          <p>
            Improve pipeline performance, reduce spending on cloud computing and
            eliminate nondeterministic outcomes. Let us put hours back in
            your day by building automation that gets out of the way.
          </p>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Pipeline optimization</li>
                  <li className={classes.bold}>CI Parallelization</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>GitHub Actions migration</li>
                  <li className={classes.bold}>Improve reliability</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Self-hosted cloud runners</li>
                  <li className={classes.bold}>CD Infrastructure / GitOps</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='kubernetes' className={`row ${classes.content}`}>
        <div className={`col-3 ${classes.logoContainer}`}>
          <img className={classes.centeredLogo} src={kubernetes} />
        </div>
        <div className={`col-9 ${classes.innerContent}`}>
          <h3>Kubernetes Deployment</h3>
          <p>
            Scale your workloads seamlessly using industry best practices.
            We'll equip your team to achieve success with Kubernetes in production.
          </p>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>AWS / GCP / Azure / DO</li>
                  <li className={classes.bold}>Infrastructure as Code</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Intelligent Autoscaling</li>
                  <li className={classes.bold}>Improved Reliability</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Observability / Logging</li>
                  <li className={classes.bold}>Canary Deployment</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='developerTooling' className={`row ${classes.content}`}>
        <div className={`col-3 ${classes.logoContainer}`}>
          <img className={classes.centeredLogo} src={commandLine} />
        </div>
        <div className={`col-9 ${classes.innerContent}`}>
          <h3>Developer Tooling</h3>
          <p>
            Onboard team members faster and increase productivity by removing
            obstacles developers face every day. We'll work with your team to
            understand where the challenges are and solve them one by one.
          </p>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Reproducible Environments</li>
                  <li className={classes.bold}>VSCode Remote Containers</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Bespoke Developer CLIs</li>
                  <li className={classes.bold}>GitHub Codespaces</li>
                </ul>
              </div>
              <div className='col-lg-4 col-12'>
                <ul className={classes.serviceList}>
                  <li className={classes.bold}>Local Kubernetes development</li>
                  <li className={classes.bold}>Development on Apple M1</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(Services)
