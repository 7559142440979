import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import mobileAppLogo from 'assets/images/wama-icon.svg'
import ConceptExample from './ConceptExample'
import conceptExample1 from 'assets/images/conceptExample1.jpg'
import conceptExample2 from 'assets/images/conceptExample2.jpg'
import conceptExample3 from 'assets/images/conceptExample3.jpg'

const WebAndMobileApps = ({ classes }) => (
  <div className={classes.container}>
    <div id='softwareDevelopment' className={classes.sectionLink} />
    <div className={classes.headerInner}>
      <div className={`${classes.headerContainer} container`}>
        <img className={classes.centeredLogo} src={mobileAppLogo} />
        <h3>Software Development</h3>
      </div>
    </div>
    <ConceptExample image={conceptExample1} order={1}>
      <div id='ruby' className={classes.contentContainer}>
        <h3>Ruby / Rails</h3>
        <div>
          Have a majestic monolith? We've been working with Ruby on
          Rails since pre-1.0, and there is nothing we haven't seen.
          We love Rails for its opinionated approach, which makes a
          high level of productivity possible while maintaining
          flexibility where it counts.
        </div>
        <ul>
          <li>
            <strong>Upgrade Ruby & Rails (the GitHub/Shopify way)</strong>
          </li>
          <li>
            <strong>
              Architecture/conventions review & refactoring
            </strong>
          </li>
          <li>
            <strong>Implement testing best-practices</strong>
          </li>
        </ul>
      </div>
    </ConceptExample>
    <ConceptExample image={conceptExample2} order={3}>
      <div id='javascript' className={classes.contentContainer}>
        <h3>Javascript / React / Node</h3>
        <div>
          React and Flux Architecture bridge the gap between traditional
          web applications and a more native user experience. Or, take
          javascript beyond the browser, enabling higher productivity by
          building in a single ecosystem.
        </div>
        <ul>
          <li>
            <strong>
              Implement front-end ops best practices
            </strong>
          </li>
          <li>
            <strong>
              Achieve an effective testing strategy
            </strong>
          </li>
          <li>
            <strong>
              Scale infinitely with node & serverless architecture
            </strong>
          </li>
        </ul>
      </div>
    </ConceptExample>
    <ConceptExample image={conceptExample3} order={1}>
      <div id='elixir' className={classes.contentContainer}>
        <h3>Elixir / Phoenix</h3>
        <div>
          Elixir provides unbeatable performance, concurrency, and fault
          tolerance. Inspired by Rails, the Phoenix web framework offers
          similar advantages and has a vibrant/growing community.
        </div>
        <ul>
          <li>
            <strong>Add experience to your team</strong>
          </li>
          <li>
            <strong>Scale your application in production</strong>
          </li>
          <li>
            <strong>Gain confidence with automated testing</strong>
          </li>
        </ul>
      </div>
    </ConceptExample>
  </div>
)

export default injectSheet(style)(WebAndMobileApps)
